import * as Types from '@worx.squad/app-sdk/base-types';

import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SuperAdminTableDataSubscriptionVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SuperAdminTableDataSubscription = {
  __typename?: 'subscription_root';
  organization: Array<{
    __typename?: 'organization';
    organization_name: string;
    created_at: any;
    address?: { __typename?: 'address'; address_line: string } | null;
    organization_employees_aggregate: {
      __typename?: 'organization_employee_aggregate';
      aggregate?: {
        __typename?: 'organization_employee_aggregate_fields';
        count: number;
      } | null;
    };
    organization_admins: Array<{
      __typename?: 'organization_admin';
      user?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
        profile: Array<{ __typename?: 'profile'; id: any }>;
      } | null;
    }>;
  }>;
};

export const SuperAdminTableDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'superAdminTableData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address_line' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'organization_employees_aggregate',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_admins' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is_owner' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'display_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useSuperAdminTableDataSubscription<
  TData = SuperAdminTableDataSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<SuperAdminTableDataSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<SuperAdminTableDataSubscription, TData>,
) {
  return Urql.useSubscription<
    SuperAdminTableDataSubscription,
    TData,
    SuperAdminTableDataSubscriptionVariables
  >({ query: SuperAdminTableDataDocument, ...options }, handler);
}
